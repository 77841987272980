import { useFetchCurrentUser, useFetchOrganizationOfCurrentUser, useIsOrganizationUsingStim } from '@MP/api/authApi';
import { useHasCardInfo } from '@MP/api/surveyApi';
import PatientProvider from '@MP/feature/patient/PatientProvider';
import ScanModuleProvider from '@MP/feature/scan/ScanModuleProvider';
import NoticeProvider from '@MP/NoticeProvider';
import PaymentAlarmProvider from '@MP/page/Payment/PaymentAlarmProvider';
import PaymentFailAlarmProvider from '@MP/page/Payment/PaymentFailAlarmProvider';
import { useGlobalStore } from '@MP/store/GlobalUIStore';
import { Box } from '@mui/joy';
import React from 'react';
import { Outlet } from 'react-router';
import Sidebar from '../common/component/Sidebar';
import useLogout from '@MP/hook/useLogout';
import { Notice } from '@MP/api/typs';

const MainLayout = () => {
  const globalStore = useGlobalStore();
  const { data: isUsingStim } = useIsOrganizationUsingStim();
  // const { data: hasCardInfo } = useHasCardInfo();
  const { isError } = useFetchCurrentUser();
  const { logout } = useLogout();
  const { data: organization } = useFetchOrganizationOfCurrentUser();

  React.useEffect(() => {
    window.MinddPlugin.setOrgName(organization?.name);
  }, [organization]);

  React.useEffect(() => {
    if (isError) {
      logout();
    }
  }, [logout, isError]);

  // const notices: Notice[] = React.useMemo(() => {
  //   if (isUsingStim !== undefined && hasCardInfo !== undefined) {
  //     return globalStore.getNotice(true, hasCardInfo, isUsingStim);
  //   }
  //   return [];
  // }, [isUsingStim, hasCardInfo, globalStore]);

  return (
    <PatientProvider>
      {/* <NoticeProvider notices={notices} onClose={(n, o) => globalStore.setHideNotice(n, o)} /> */}
      <ScanModuleProvider>
        <Box height={'100vh'}>
          <Sidebar />
          <Box sx={{ ml: '70px', height: '100%', bgcolor: '#F5F5F5' }}>
            <Outlet />
          </Box>
        </Box>
      </ScanModuleProvider>
      {/* <PaymentAlarmProvider />
      <PaymentFailAlarmProvider /> */}
    </PatientProvider>
  );
};

export default MainLayout;
