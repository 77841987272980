import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import {
  Agreement,
  AgreementControllerApi,
  AppointmentControllerApi,
  AppointmentCreateRequest,
  AppointmentUpdateRequest,
  CancellationRequest,
  InsuranceFeeInfoList,
  Invoice,
  InvoiceControllerApi,
  InvoicePage,
  OrgPreferenceControllerApi,
  PaidSessionRecord,
  PaidSessionRecordControllerApi,
  PaidSurveyRecord,
  PaidSurveyRecordControllerApi,
  PaymentCardControllerApi,
  PaymentControllerApi,
  ServiceType,
  SleepSessionControllerApi,
  SleepSessionCreateRequest,
  SubjectMeta,
  SubjectMetaCreateRequest,
  SubjectMetaPage,
  SubjectMetaUpdateRequest,
  SurveyControllerApi,
  SurveySession,
  SurveySessionCreateRequest,
  UpdateRequest,
  UserControllerApi,
} from './survey';
const SURVEY_URL = process.env.REACT_APP_SURVEY_SERVER_URL;
const userControllerApi = new UserControllerApi(undefined, SURVEY_URL);
const surveyControllerApi = new SurveyControllerApi(undefined, SURVEY_URL);
const agreementControllerApi = new AgreementControllerApi(undefined, SURVEY_URL);
const paymentCardController = new PaymentCardControllerApi(undefined, SURVEY_URL);
const paymentController = new PaymentControllerApi(undefined, SURVEY_URL);
const invoiceController = new InvoiceControllerApi(undefined, SURVEY_URL);
const paidSurveyRecordController = new PaidSurveyRecordControllerApi(undefined, SURVEY_URL);
const paidSessionRecordController = new PaidSessionRecordControllerApi(undefined, SURVEY_URL);
const orgPreferenceController = new OrgPreferenceControllerApi(undefined, SURVEY_URL);
const appointmentControllerApi = new AppointmentControllerApi(undefined, SURVEY_URL);
const sleepControllerApi = new SleepSessionControllerApi(undefined, SURVEY_URL);

const ORG_DEFAULT_PREFERENCE_KEY = 'ORG_DEFAULT_PREFERENCE_KEY';
const ORG_PREFERENCE_KEY = 'ORG_PREFERENCE_KEY';
const CARD_INFO_KEY = 'CARD_INFO';
const INVOICE_KEY = 'INVOICE';
const SURVEY_RECORD_KEY = 'SURVEY_RECORD';
const SESSION_RECORD_KEY = 'SESSION_RECORD';
const LAST_PAYMENT_STATUS_KEY = 'LAST_PAYMENT_STATUS';
const SESSION_URL_KEY = 'SESSION_URL';
const SESSION_LIST_KEY = 'SESSION_LIST_KEY';
const APPOINTMENTS_KEY = 'APPOINTMENTS_KEY';
const SLEEP_SESSION_LIST_KEY = 'SLEEP_SESSION_LIST_KEY';
const SLEEP_DIARY_LIST_KEY = 'SLEEP_DIARY_LIST_KEY';

export const useFetchUsers = (searchText: string) => {
  return useInfiniteQuery<
    SubjectMetaPage,
    AxiosError,
    InfiniteData<SubjectMeta, SubjectMeta | undefined>,
    any,
    SubjectMeta | undefined
  >({
    queryKey: ['users', searchText],
    queryFn: ({ pageParam: lastUser }) => {
      return {
        isLast: true,
        subjectMetas: [
          {
            id: '1',
            userUid: '1',
            name: '테스트 사용자1',
            code: 'TEST1',
            birthYear: 2025,
            contactlessAgreementStatus: 'AGREED',
            thirdPartyAgreementStatus: 'AGREED',
            stimAgreementStatus: 'AGREED',
            createdTime: '',
          },
          {
            id: '2',
            userUid: '2',
            name: '테스트 사용자2',
            code: 'TEST2',
            birthYear: 2025,
            contactlessAgreementStatus: 'AGREED',
            thirdPartyAgreementStatus: 'AGREED',
            stimAgreementStatus: 'AGREED',
            createdTime: '',
          },
        ],
      };
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => {
      const users = lastPage.subjectMetas;
      if (users.length > 0 && !lastPage.isLast) {
        return users[users.length - 1];
      }
      return undefined;
    },
    select: (data) => {
      const temp: SubjectMeta[] = [];

      data.pages.forEach((page) => {
        temp.push(...page.subjectMetas);
      });

      return {
        pages: temp,
        pageParams: data.pageParams,
      };
    },
  });
};

export const useFetchAgreements = (subjectMetaId?: string) => {
  return useQuery<{ subjectMetaId: string; agreements: Agreement[] }>({
    queryKey: ['agreements', subjectMetaId],
    queryFn: () => {
      return {
        subjectMetaId: subjectMetaId || '',
        agreements: [{ expired: false, userUid: '', targetOrgId: 1, service: ServiceType.Policy, createdTime: '' }],
      };
    },
    enabled: !!subjectMetaId,
    gcTime: 0,
  });
};

export const useSendCreateUserLink = () => {
  return useMutation<void, AxiosError, SubjectMetaCreateRequest>({
    mutationFn: (body) => userControllerApi.sendCreateUserLink(body).then((res) => res.data),
  });
};

export const useFetchPatient = () => {
  return useMutation<SubjectMeta, AxiosError, string>({
    mutationFn: (subjectMetaId) => userControllerApi.getUser(subjectMetaId).then((res) => res.data),
  });
};

export const useUpdatePatient = () => {
  return useMutation<SubjectMeta, AxiosError, { subjectMetaId: string; body: SubjectMetaUpdateRequest }>({
    mutationFn: ({ subjectMetaId, body }) =>
      userControllerApi.updateSubjectMeta(subjectMetaId, body).then((res) => res.data),
  });
};

export const useDeletePatient = () => {
  return useMutation<any, AxiosError, string>({
    mutationFn: async (subjectMetaId) => userControllerApi.deleteSubjectMeta(subjectMetaId),
  });
};

export const useExistsCode = () => {
  return useMutation<boolean, AxiosError, string>({
    mutationFn: async (code) => userControllerApi.existToSubjectList(code).then((res) => res.data),
  });
};

export const useExistsPhoneNumber = () => {
  return useMutation<boolean, AxiosError, string>({
    mutationFn: async (phoneNumber) =>
      userControllerApi.existToSubjectList(undefined, phoneNumber).then((res) => res.data),
  });
};

export const useCreateSession = () => {
  const client = useQueryClient();
  return useMutation<SurveySession, AxiosError, SurveySessionCreateRequest>({
    mutationFn: (body) => surveyControllerApi.createSurvey(body).then((res) => res.data),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: [SESSION_LIST_KEY, data.subjectMetaId] });
    },
  });
};

export const useDeleteSession = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, { sessionId: string; subjectMetaId: string }>({
    mutationFn: ({ subjectMetaId, sessionId }) => surveyControllerApi.deleteSurveySessions([sessionId], subjectMetaId),
    onSuccess: (_, { subjectMetaId }) => {
      client.invalidateQueries({ queryKey: [SESSION_LIST_KEY, subjectMetaId] });
    },
  });
};

export const useSendSession = () => {
  return useMutation<string, AxiosError, string>({
    mutationFn: (sessionId) => surveyControllerApi.sendSurvey(sessionId).then((res) => res.data),
  });
};

export const useGetSessionUrl = () => {
  return useMutation<string, AxiosError, string>({
    mutationFn: (sessionId) => surveyControllerApi.getSurveyUrl(sessionId).then((res) => res.data),
  });
};

export const useFetchSessionUrl = (sessionId: string) => {
  return useQuery({
    queryKey: [SESSION_URL_KEY],
    queryFn: () => surveyControllerApi.getSurveyUrl(sessionId).then((res) => res.data),
  });
};

export const useFetchInsuranceFeeInfo = () => {
  return useMutation<InsuranceFeeInfoList, AxiosError, { subjectMetaId: string; yearMonth?: string }>({
    mutationFn: ({ subjectMetaId, yearMonth }) =>
      surveyControllerApi.getInsuranceFeeInfo(subjectMetaId, yearMonth).then((res) => res.data),
  });
};

export const useCreateAgreement = () => {
  return useMutation<Agreement[], AxiosError, ServiceType[]>({
    mutationFn: (body) => agreementControllerApi.createAgreement(body).then((res) => res.data),
  });
};

export const useSendAgreement = () => {
  return useMutation<string, AxiosError, { subjectMetaId: string; service: ServiceType[] }>({
    mutationFn: ({ subjectMetaId, service }) =>
      agreementControllerApi.send(subjectMetaId, { agreements: service }).then((res) => res.data),
  });
};

export const useGetAgreementUrl = () => {
  return useMutation<string, AxiosError, { subjectMetaId: string; service: ServiceType[] }>({
    mutationFn: ({ subjectMetaId, service }) =>
      agreementControllerApi.getUrl(subjectMetaId, { agreements: service }).then((res) => res.data),
  });
};

const useFetchSessions = (subjectMetaId?: string) => {
  return useQuery({
    queryKey: [SESSION_LIST_KEY, subjectMetaId],
    queryFn: async () => surveyControllerApi.getSurveySessions(subjectMetaId!).then((res) => res.data.reverse()),
    enabled: !!subjectMetaId,
  });
};

export const useFetchQuestionnaireSessions = (type: 'done' | 'all' | 'inprogress', subjectMetaId?: string) => {
  const res = useFetchSessions(subjectMetaId);

  return {
    ...res,
    data: React.useMemo(() => {
      return res.data
        ?.filter((s) => s.questionnaireIds.length > 0)
        .filter((s) => {
          if (type === 'done') {
            return s.questionnaireIds.length === s.answerSheets.length;
          } else if (type === 'inprogress') {
            return s.questionnaireIds.length > s.answerSheets.length;
          }
          return s;
        });
    }, [res.data, type]),
  };
};

export const useFetchCardInfo = () => {
  return useQuery({
    queryKey: [CARD_INFO_KEY],
    queryFn: () =>
      paymentCardController
        .getCardInfo()
        .then((res) => res.data)
        .catch((e: AxiosError) => {
          if (e.response?.status === 404) {
            return null;
          }
          return Promise.reject(e);
        }),
  });
};

export const useHasCardInfo = () => {
  const res = useFetchCardInfo();
  return {
    ...res,
    data: React.useMemo(() => {
      if (!!res.data) {
        return true;
      } else if (res.data === null) {
        return false;
      }
      return undefined;
    }, [res.data]),
  };
};

export const useUnregisterCard = () => {
  const client = useQueryClient();
  return useMutation<Invoice[], AxiosError>({
    mutationFn: () => paymentCardController.deleteCard().then((res) => res.data),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [CARD_INFO_KEY] });
    },
  });
};

export const useRegisterCard = () => {
  const client = useQueryClient();
  return useMutation<any, void, string>({
    mutationFn: (receiptId) => paymentCardController.registerCard(receiptId),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [CARD_INFO_KEY] });
    },
  });
};

export const useUpdateCard = () => {
  const client = useQueryClient();
  return useMutation<any, void, string>({
    mutationFn: (receiptId) => paymentCardController.changeCard(receiptId),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [CARD_INFO_KEY] });
    },
  });
};

export const useFetchSurveyRecordByInvoiceId = (invoiceId: string, enabled?: boolean) => {
  return useQuery<PaidSurveyRecord[]>({
    queryKey: [SURVEY_RECORD_KEY, invoiceId],
    queryFn: async () => await paidSurveyRecordController.getRecordByInvoice(invoiceId).then((res) => res.data),
    gcTime: 0,
    enabled: enabled,
  });
};

export const useFetchSurveyRecords = (yearMonth: string) => {
  return useQuery<PaidSurveyRecord[]>({
    queryKey: [SURVEY_RECORD_KEY, yearMonth],
    queryFn: async () => await paidSurveyRecordController.getMonthlyRecords(yearMonth).then((res) => res.data),
    gcTime: 0,
  });
};

export const useFetchSessionRecordByInvoiceId = (invoiceId: string, enabled?: boolean) => {
  return useQuery<Record<string, PaidSessionRecord[]>>({
    queryKey: [SESSION_RECORD_KEY, invoiceId],
    queryFn: async () =>
      await paidSessionRecordController.getRecordByInvoice1(invoiceId).then((res) => {
        const data = {} as Record<string, PaidSessionRecord[]>;
        Object.keys(res.data).forEach((key) => {
          data[key] = res.data[key].reverse();
        });
        return data;
      }),
    gcTime: 0,
    enabled: enabled,
  });
};

export const useFetchSessionRecords = (yearMonth: string) => {
  return useQuery<Record<string, PaidSessionRecord[]>>({
    queryKey: [SESSION_RECORD_KEY, yearMonth],
    queryFn: async () =>
      await paidSessionRecordController.getMonthlyRecords1(yearMonth).then((res) => {
        const data = {} as Record<string, PaidSessionRecord[]>;
        Object.keys(res.data).forEach((key) => {
          data[key] = res.data[key].reverse();
        });
        return data;
      }),
    gcTime: 0,
  });
};

export const useFetchInvoices = (page: number, limit: number) => {
  return useQuery<InvoicePage>({
    queryKey: [INVOICE_KEY, page, limit],
    queryFn: () => invoiceController.getInvoices(page, limit).then((res) => res.data),
    gcTime: 0,
  });
};
export const useFetchInvoicesBy = (yearMonth: string) => {
  return useQuery<Invoice[]>({
    queryKey: [INVOICE_KEY, yearMonth],
    queryFn: () => invoiceController.getInvoicesBy(yearMonth).then((res) => res.data),
    gcTime: 0,
  });
};

export const useFetchLastPaymentStatus = () => {
  return useQuery<boolean>({
    queryKey: [LAST_PAYMENT_STATUS_KEY],
    queryFn: () => paymentController.getLastPaymentStatus().then((res) => res.data.success),
  });
};

export const useFetchDefaultPreference = () => {
  return useQuery({
    queryKey: [ORG_DEFAULT_PREFERENCE_KEY],
    queryFn: () => orgPreferenceController.getDefaultPreference().then((res) => res.data),
  });
};

export const useFetchPreference = () => {
  return useQuery({
    queryKey: [ORG_PREFERENCE_KEY],
    queryFn: () => orgPreferenceController.getPreference().then((res) => res.data),
  });
};

export const useUpdatePreference = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, UpdateRequest>({
    mutationFn: (pref) => orgPreferenceController.updatePreference(pref),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [ORG_PREFERENCE_KEY] });
    },
  });
};

export const useCreateAppointment = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, AppointmentCreateRequest>({
    mutationFn: async (body) => appointmentControllerApi.createAppointment(body).then((res) => res.data),
    onSuccess: () => client.invalidateQueries({ queryKey: [APPOINTMENTS_KEY] }),
  });
};

export const useUpdateAppointment = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, { id: string; body: AppointmentUpdateRequest }>({
    mutationFn: async ({ id, body }) => appointmentControllerApi.updateAppointment(id, body).then((res) => res.data),
    onSuccess: () => client.invalidateQueries({ queryKey: [APPOINTMENTS_KEY] }),
  });
};

export const useDeleteAppointment = () => {
  return useMutation<any, AxiosError, string>({
    mutationFn: async (id) => appointmentControllerApi.deleteAppointment(id).then((res) => res.data),
  });
};

const useFetchAppointmentAll = () => {
  return useQuery({
    queryKey: [APPOINTMENTS_KEY],
    queryFn: () => appointmentControllerApi.getAppointments().then((res) => res.data.reverse()),
  });
};

export const useFetchAppointmentList = (subjectMetaId?: string) => {
  const res = useFetchAppointmentAll();
  return {
    ...res,
    data: React.useMemo(() => {
      if (subjectMetaId && res.data) {
        return res.data.filter((v) => v.subjectMetaId === subjectMetaId);
      }
      return undefined;
    }, [res.data, subjectMetaId]),
  };
};

export const useFetchSleepSessions = (subjectMetaId?: string) => {
  return useQuery({
    queryKey: [SLEEP_SESSION_LIST_KEY, subjectMetaId],
    queryFn: () => sleepControllerApi.getSleepSessions(subjectMetaId!!).then((res) => res.data.reverse()),
    enabled: !!subjectMetaId,
  });
};

export const useFetchSleepDiaries = (sessionId?: string) => {
  return useQuery({
    queryKey: [SLEEP_DIARY_LIST_KEY, sessionId],
    queryFn: () =>
      sleepControllerApi.getSleepDiaries(sessionId!!).then((res) => res.data.sort((a, b) => a.sequence - b.sequence)),
    enabled: !!sessionId,
  });
};

export const useCreateSleepSession = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, SleepSessionCreateRequest>({
    mutationFn: async (body) => sleepControllerApi.createSleepSession(body).then((res) => res.data),
    onSuccess: (_, body) => client.invalidateQueries({ queryKey: [SLEEP_SESSION_LIST_KEY, body.subjectMetaId] }),
  });
};

export const useCancelSleepSession = (subjectMetaId: string, sessionId: string) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError, CancellationRequest>({
    mutationFn: async (body) => sleepControllerApi.cancelSleepSession(sessionId, body).then((res) => res.data),
    onSuccess: (_, body) => client.invalidateQueries({ queryKey: [SLEEP_SESSION_LIST_KEY, subjectMetaId] }),
  });
};
