import { SubjectMeta } from '@MP/api/survey';
import PatientDeleteConfirmDialog from '@MP/feature/patient/PatientDeleteConfirmDialog';
import PatientEditDialog from '@MP/feature/patient/PatientEditDialog';
import { usePatientStore } from '@MP/feature/patient/PatientProvider';
import SettingIcon from '@MP/icon/SettingIcon';
import { Button, Divider, Stack, Typography } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { BsArrowsAngleContract, BsArrowsAngleExpand } from 'react-icons/bs';
import MenuTabs from './MenuTabs';
import { usePatientPageStore } from './PatientPageStore';

const commonBarStyle = {
  borderRadius: '8px',
  px: '16px',
};

const expandedBarStyle = {
  px: '16px',
  position: 'sticky',
  left: 0,
  right: 0,
  top: 0,
};

const commonTabStyle = {
  flexGrow: 1,
};

const expandedTabStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  alignItems: 'center',
};

const PatientRightTopContents = observer(() => {
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const store = usePatientPageStore();
  const patientStore = usePatientStore();

  const expanded = store.expanded;

  const handleOpenDelete = () => {
    setOpenDelete(true);
    setOpenEdit(false);
  };

  const handleDelete = (user: SubjectMeta, result: boolean) => {
    if (result) {
      patientStore.deleteUser(user.id);
    }
    setOpenDelete(false);
  };

  return (
    <Stack position={'relative'} minHeight={55} bgcolor={'common.white'} justifyContent={'center'} borderRadius={'8px'}>
      <Stack direction={'row'} alignItems={'center'} sx={expanded ? expandedBarStyle : commonBarStyle}>
        {expanded && (
          <Stack
            flexGrow={1}
            direction={'row'}
            spacing={'4px'}
            divider={<Divider orientation="vertical" sx={{ height: '10px', alignSelf: 'center' }} />}>
            <Typography sx={{ fontSize: '14px' }}>{patientStore.user?.birthYear}년생 </Typography>
            <Typography sx={{ fontSize: '14px' }}> {patientStore.user?.name}</Typography>
          </Stack>
        )}
        <Stack sx={expanded ? expandedTabStyle : commonTabStyle}>
          <MenuTabs />
        </Stack>
        {/* 
        <Button
          variant="plain"
          startDecorator={<SettingIcon type="dark" width={16} />}
          color="neutral"
          onClick={() => setOpenEdit(true)}>
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>환자 정보 수정</Typography>
        </Button> */}

        <Button
          variant="plain"
          color="neutral"
          startDecorator={expanded ? <BsArrowsAngleContract size={12} /> : <BsArrowsAngleExpand size={12} />}
          onClick={() => store.setExpanded(!expanded)}>
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{expanded ? '화면축소' : '전체화면'}</Typography>
        </Button>
      </Stack>
      {patientStore.user && openEdit && (
        <PatientEditDialog
          value={patientStore.user}
          agreements={patientStore.userAgreements}
          onClose={() => setOpenEdit(false)}
          onDelete={handleOpenDelete}
        />
      )}
      {patientStore.user && openDelete && (
        <PatientDeleteConfirmDialog user={patientStore.user} onResult={handleDelete} />
      )}
    </Stack>
  );
});

export default PatientRightTopContents;
