import { useIsOrganizationUsingStim } from '@MP/api/authApi';
import useLogout from '@MP/hook/useLogout';
import CreditCardIcon from '@MP/icon/CreditCardIcon';
import LogoIcon from '@MP/icon/LogoIcon';
import LogoutIcon from '@MP/icon/LogoutIcon';
import PatientIcon from '@MP/icon/PatientIcon';
import SettingIcon from '@MP/icon/SettingIcon';
import { Dropdown, IconButton, ListDivider, ListItem, Menu, MenuButton, MenuItem, Stack, Typography } from '@mui/joy';
import React from 'react';
import { BsClipboardPlus } from 'react-icons/bs';
import { CiMenuKebab } from 'react-icons/ci';
import { useLocation, useNavigate } from 'react-router';
import AccountDialog from './account/AccountDialog';
import NaviButton from './NaviButton';

const Sidebar = () => {
  const [openProfile, setOpenProfile] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { data: isUsingStim } = useIsOrganizationUsingStim();
  const { logout } = useLogout();
  return (
    <Stack
      sx={{
        height: '100vh',
        width: 70,
        position: 'fixed',
        bgcolor: 'primary.500',
        px: '8px',
        py: '16px',
        alignItems: 'center',
        zIndex: 2,
      }}>
      <NaviButton icon={<LogoIcon />} name="" onClick={() => navigate('/')} />
      <Stack mt={'24px'} spacing={'16px'}>
        <NaviButton
          active={location.pathname.startsWith('/patient')}
          icon={<PatientIcon />}
          name="환자 관리"
          onClick={() => navigate('/patient')}
        />
        {/* {isUsingStim && (
          <NaviButton
            active={location.pathname.startsWith('/stim')}
            icon={<BsClipboardPlus size={22} />}
            name="STIM 처방 내역"
            onClick={() => navigate('/stim')}
          />
        )}
        <NaviButton
          active={location.pathname.startsWith('/mindd/payment')}
          icon={<CreditCardIcon />}
          name="정산/결제 관리"
          onClick={() => navigate('/mindd/payment/usage')}
        /> */}

        {/* <NaviButton icon={<RemoteIcon />} name="원격 진료" onClick={() => navigate('/patient')} /> */}
      </Stack>
      <Stack flexGrow={1} justifyContent={'flex-end'} mt={'43px'} spacing={'16px'}>
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: 'solid', color: 'primary', sx: { width: 48, height: 48 } } }}>
            <CiMenuKebab size={30} />
          </MenuButton>
          <Menu placement="right-start">
            {/* <ListItem>
              <Typography level="body-xs" fontWeight={600}>
                와이브레인 서비스
              </Typography>
            </ListItem>
            <MenuItem onClick={() => (window.location.href = `${process.env.REACT_APP_RESEARCH_WEB_URL}`)}>
              <Typography level="body-sm" sx={{ color: '#333' }}>
                리서치 프로젝트 바로가기
              </Typography>
            </MenuItem>
            <ListDivider /> */}
            <MenuItem onClick={() => window.open('https://sites.google.com/ybrain.com/portal/mindd', '_blank')}>
              <Typography level="body-sm" sx={{ color: '#333' }}>
                고객 지원
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => window.open(process.env.REACT_APP_TDCS_GUIDE_URL, '_blank')}>
              <Typography level="body-sm" sx={{ color: '#333' }}>
                tDS치료 지침
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                window.open(
                  'https://m.post.naver.com/my/series/detail.naver?seriesNo=670866&memberNo=56101780',
                  '_blank'
                )
              }>
              <Typography level="body-sm" sx={{ color: '#333' }}>
                MINDD 사용법
              </Typography>
            </MenuItem>
            <ListDivider />
            {/* <MenuItem onClick={() => setOpenProfile(true)}>
              <Typography level="body-sm" sx={{ color: '#333' }}>
                내 정보
              </Typography>
            </MenuItem> */}
          </Menu>
        </Dropdown>
        <NaviButton
          active={location.pathname.startsWith('/setting')}
          icon={<SettingIcon />}
          name="설정"
          onClick={() => {}}
        />
        <NaviButton icon={<LogoutIcon />} name="로그아웃" onClick={() => {}} />
      </Stack>
      <AccountDialog open={openProfile} onClose={() => setOpenProfile(false)} />
    </Stack>
  );
};

export default Sidebar;
